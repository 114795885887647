<!-- 导航栏 -->

<template>
  <div class="model">
    <div class="container">
      <div class="nav">
        <div class="nav-left">
          <img src="@/assets/logo.png" alt="" />
        </div>
        <div class="nav-right">
          <div
            class="nav_list"
            v-for="(item, index) in navList"
            :key="index"
            @click="goLink(index, item.path)"
            :style="{ fontWeight: indexNum === item.id ? 900 : 400 }"
          >
            <span>
              {{ item.name }}
            </span>
          </div>

          <div class="nav_list" @click="goLink(3, '')">
            <a
              class="link"
              :style="{ fontWeight: indexNum === 4 ? 900 : 400 }"
              href="#bottom"
            >
              CONTACT US
            </a>
          </div>
        </div>
      </div>

      <div class="contend">
        <transition name="transitionRouter">
          <router-view></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      indexNum: 1,
      navList: [
        {
          id: 1,
          name: "HOME",
          path: "/home/index",
        },
        {
          id: 2,
          name: "SERVICES",
          path: "/services/index",
        },
        {
          id: 3,
          name: "ABOUT US",
          path: "/about/index",
        },
      ],
    };
  },
  methods: {
    goLink(index, path) {
      console.log(index);
      this.indexNum = index + 1;
      this.$router.push({ path });
    },
  },
};
</script>

<style lang="scss" scoped>
.model {
  width: 100%;
  margin: 0;
  .container {
    .nav {
      width: 100%;
      height: 0.88rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      z-index: 99;
      top: 0;
      background: #ffffff;
      .nav-left {
        display: flex;
        align-items: center;
        height: 0.88rem;
        margin-left: 2.8rem;
        img {
          width: 1.88rem;
          height: 0.61rem;
        }
      }
      .nav-right {
        width: 13.34rem;
        height: 0.88rem;
        background: url("@/assets/top.png") no-repeat;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 2.8rem 0 2.1rem;
        .nav_list {
          font-size: 0.3rem;
          color: #ffffff;
          line-height: 0.46rem;
          cursor: pointer;
        }
      }
    }
    .contend {
      position: relative;
      // height: 90%;
    }
  }
}
.link {
  color: #ffffff;
}
</style>
